import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo-header.svg'
import iconTel from '../../assets/images/icon-tel-g.svg'
import '../../assets/scss/components/header.scss'
import gsap from 'gsap/all'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import ScrollLock from 'react-scrolllock'
import useWindowDimensions from '../../assets/utils/mediaQueryList'

const Header = () => {
  gsap.registerPlugin(ScrollToPlugin)
  gsap.defaults({ ease: 'ease', duration: 0 })
  const [menuTl] = useState(gsap.timeline({ paused: true }))
  const [toggle, setToggle] = useState(false)
  const [active, setActive] = useState(false)
  const [isScroll, setIsScroll] = useState(false)
  const mediaQuery = useWindowDimensions()

  const toggleClass = event => {
    event.preventDefault()
    setToggle(!toggle)
    setIsScroll(!isScroll)
    menuTl.reversed(!menuTl.reversed())
    if (!toggle) {
      setActive(true)
    } else {
      setTimeout(() => {
        setActive(false)
      }, 1800)
    }
  }

  useEffect(() => {
    menuTl
      .to('.header__overlay', { yPercent: 100, duration: 0.5 })
      .to('.header__itemMenu', {
        y: 0,
        stagger: 0.1,
        autoAlpha: 1,
        duration: 0.6,
        ease: 'back',
      })
      .to('.header__btnContact', { autoAlpha: 1, duration: 0.3 })
      .reverse()
  }, [])

  useEffect(() => {
    const targets = gsap.utils.toArray('.header__linkMenu')
    targets.forEach(item => {
      item.addEventListener('click', event => {
        const getHash = event.target.hash
        const getHeightHeader = document.querySelector(
          mediaQuery.width >= 992 ? 'header.header' : '.header__wrapMobile'
        ).clientHeight

        menuTl.reversed(true)
        setToggle(false)
        setIsScroll(false)
        setTimeout(() => {
          setActive(false)
        }, 1500)

        gsap.to(window, {
          duration: 1,
          scrollTo: {
            y: `[data-section='${getHash}']`,
            offsetY: getHeightHeader,
          },
          ease: 'power4.inOut',
          delay: mediaQuery.width >= 992 ? 0 : 1.2,
          onComplete: () => {
            document.querySelector('.header__inner').classList.add('is-closing')
          }
        })
      })
    })
  }, [])

  return (
    <header className={`header${active ? ' is-closing' : ''}`}>
      <div className="header__overlay" aria-hidden="true"></div>
      <div className="header__background" aria-hidden="true"></div>
      <div className={`header__inner${!active ? ' is-closing' : ''}`}>
        <div className="header__wrapMobile">
          <a href="/" className="header__logo">
            <img src={logo} alt="Masspap" />
          </a>
          <a
            href=""
            className="header__btnMenu"
            onClick={event => toggleClass(event)}
          >
            <svg
              className={`ham hamRotate ham4 ${toggle ? 'active' : ''}`}
              viewBox="0 0 100 100"
              width="46"
            >
              <path
                className="line top"
                d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
              />
              <path className="line middle" d="m 70,50 h -40" />
              <path
                className="line bottom"
                d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
              />
            </svg>
          </a>
        </div>
        <ScrollLock isActive={isScroll} />
        <div className="header__wrapMenu">
          <nav className="header__menu">
            <ul className="header__listMenu">
              <li className="header__itemMenu">
                <a href="#about" className="header__linkMenu">
                  Meistä
                </a>
              </li>
              <li className="header__itemMenu">
                <a href="#products" className="header__linkMenu">
                  Tuotteet
                </a>
              </li>
              <li className="header__itemMenu">
                <a href="#benefits" className="header__linkMenu">
                  Miksi Masspap?
                </a>
              </li>
              <li className="header__itemMenu">
                <a href="#download" className="header__linkMenu">
                  Lataa esitteitä
                </a>
              </li>
            </ul>
            <a href="tel:" className="header__btnContact">
              <i className="icon-tel">
                <img src={iconTel} alt="" />
              </i>
              <span>Ota yhteyttä</span>
            </a>
          </nav>
        </div>
        <div className="header__wrapButton">
          <a href="mailto:toimisto@masspap.fi" className="header__button">
            Ota yhteyttä
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
