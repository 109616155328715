import React from 'react'
import { Helmet } from 'react-helmet'
import Header from "./Header/Header";

import "../assets/scss/common/_base.scss"

const TemplateWrapper = ({ children }) => {
  return (
    <>
      <Helmet >
        <html lang="en" />
        <title>Masspap</title>
        <meta name="description" content="" />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="" />
        <meta property="og:url" content="/" />
      </Helmet>
      <Header />
      <main id="wrap-page">{children}</main>
    </>
  )
}

export default TemplateWrapper
